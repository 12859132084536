import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Row, StyledDesktopRender, StyledIconImg } from '@styles/common';
import { gridiconsNoticeIcon, homeLogoImage } from '@styles/imgMapping';
import { Hamburger } from './Hamburger';
import { NetworkSelectButton } from './NetworkSelectButton';
import { NetworkSwitchList } from './NetworkSwitchList';
import { StyledContainer, StyledHeader, StyledImage, StyledNav, StyledNoticeBanner, StyledNoticeText } from './Styles';
import { UnbindAddressButton } from './UnbindAddressButton';
import useNavbarContext, { NavbarProvider } from './navbarContext';
import { Props } from './type';

const Navbar = () => {
  const { hideNetwork, hideUnbindButton, logoImgUrl = '', isMainnet, onLogoClick } = useNavbarContext();
  const { account } = useWeb3React();

  const [openNetworkList, setOpenNetworkList] = useState<boolean>(false);

  const handleLogoClick = () => {
    if (typeof onLogoClick === 'function') {
      onLogoClick();
    }
  };

  const isTestnet = !isMainnet && account;

  return (
    <StyledHeader isTestnet={!!isTestnet}>
      <StyledNav>
        {isTestnet && (
          <StyledNoticeBanner justify="center">
            <StyledIconImg src={gridiconsNoticeIcon} width="20px" marginRight="8px" />
            <StyledNoticeText>You are not on Mainnet now!</StyledNoticeText>
          </StyledNoticeBanner>
        )}
        <StyledContainer>
          <StyledImage onClick={handleLogoClick} alt="redreamer logo" src={homeLogoImage} />
          <StyledDesktopRender>
            <Row>
              {!hideNetwork && <NetworkSelectButton isTestnet={!!isTestnet} />}
              {!hideUnbindButton && <UnbindAddressButton />}
            </Row>
          </StyledDesktopRender>
          <Hamburger isOpen={openNetworkList} onClick={() => setOpenNetworkList((prevStatus) => !prevStatus)} />
        </StyledContainer>
      </StyledNav>
      <NetworkSwitchList isShow={openNetworkList} isTestnet={!!isTestnet} />
    </StyledHeader>
  );
};

const NavbarContainer = (props: Props) => (
  <NavbarProvider {...props}>
    <Navbar />
  </NavbarProvider>
);

export default NavbarContainer;
