import React, { useEffect, useState } from 'react';
import { signTypedData as joyIdSignTypedData } from '@joyid/evm';
import { useWeb3React } from '@web3-react/core';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { IUtilityCollectionById, useGetUtilityCollection } from '@api/getUtilityCollection';
import { Logger } from '@api/logger';
import { Loading } from '@components/Loading';
import { RefreshButton } from '@components/RefreshButton';
import Page from '@pages/Page';
import useApplicationContext from '@providers/applicationContext';
import { StyledDesktopRender, StyledMobileRender } from '@styles/common';
import { useGetNFTByUtilityCollectionId } from '@/api/getNFTByUtilityCollectionId';
import { useGetRedeemMessage } from '@/api/getRedeemMessage';
import { useGetUtilityData } from '@/api/getUtilityData';
import { UtilityType } from '@/api/getUtilityData/type';
import { useRedeem } from '@/api/redeem';
import { AfterRedeemedType } from '@/api/redeem/type';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import loadingEffect from '@/loading.json';
import { AppError, usePageContext } from '@/providers/pageProvider';
import { signTypedData } from '@/rpc';
import { utilityCollectionDefaultImg } from '@/styles/imgMapping';
import { CollectionCard, IUtilityCollectionNFTs } from './CollectionCard';
import { EventIsEmpty } from './EventIsEmpty';
import { EventIsFull } from './EventIsFull';
import { QrCodeModal } from './QrCodeModal';
import { RedemptionInformation } from './RedemptionInformation';
import {
  StyledContainer,
  StyledNtfsRow,
  StyledRefreshContainer,
  StyledSelectNftRow,
  StyledSelectNftSubTitle,
  StyledSelectNftTitle,
  StyledWrapper,
} from './Styles';

const CollectionsPage = () => {
  const { account, provider, chainId: metamaskChainId } = useWeb3React();
  const { okxAddress, okxChainId, okxProvider } = useOKXWallet();
  const { joyIdAddress, joyIdchainId } = useJoyIdWallet();

  const { currentChainType } = useApplicationContext();
  const { updateError } = usePageContext();

  const [utilityCollection, setUtilityCollection] = useState<IUtilityCollectionById | undefined>();
  const [nfts, setNFTs] = useState<IUtilityCollectionNFTs[]>([]);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [qrCode, setQrCode] = useState<string>('');
  const [type, setType] = useState<AfterRedeemedType>(AfterRedeemedType.QR_CODE);
  const [event, setEvent] = useState<{ eventName: string; eventDescription: string }>({
    eventName: '',
    eventDescription: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { utilityCollectionId } = useParams<{ utilityCollectionId: string }>();

  const { getUtilityData, loadingGetUtilityData } = useGetUtilityData();
  const { redeem, loadingRedeem } = useRedeem();
  const { getUtilityCollection, loadingGetUtilityCollection } = useGetUtilityCollection();
  const { getRedeemMessage, loadingGetRedeemMessage } = useGetRedeemMessage();
  const { getNFTsByUtilityCollectionId, loadingGetNFTsByUtilityCollectionId } = useGetNFTByUtilityCollectionId();

  const loading =
    loadingGetUtilityCollection ||
    loadingRedeem ||
    loadingGetRedeemMessage ||
    loadingGetNFTsByUtilityCollectionId ||
    loadingGetUtilityData ||
    isLoading;
  const address = account || okxAddress || joyIdAddress;
  const chainId = metamaskChainId || okxChainId || joyIdchainId;

  const formatEndTime = utilityCollection ? moment(utilityCollection.endTime).format('YYYY/M/D HH:mm') : '';
  const isUtilityCollectionEnd = moment().isAfter(formatEndTime);

  const refreshNfts = () => {
    setRefreshKey((prev) => prev + 1);
  };

  const handleClick = async (nft: IUtilityCollectionNFTs) => {
    if (loading) return;
    if (account) {
      await evmHandler(nft);
    }
    if (okxAddress) {
      await okxHandler(nft);
    }
    if (joyIdAddress) {
      await joyIdHandler(nft);
    }
  };

  const evmHandler = async ({ contractAddress, tokenId }: IUtilityCollectionNFTs) => {
    try {
      const value = {
        type: 'evm',
        chainId: Number(chainId),
        utilityCollectionId,
        contractAddress,
        tokenId: Number(tokenId),
        address: account!,
      };
      const redeemMessage = await getRedeemMessage(value);
      const signature = await signTypedData(provider, account!, redeemMessage);
      const { utilityUri } = await redeem({
        type: 'evm',
        redeemMessage,
        signature,
      });

      if (utilityUri) {
        // TODO
        const { type, value } = await getUtilityData({
          utilityUri,
          address: account,
        });
        setQrCode(value);

        if (type === UtilityType.QR_CODE) {
          setType(AfterRedeemedType.QR_CODE);
        } else if (type === UtilityType.STRING) {
          setType(AfterRedeemedType.QR_STRING);
        }
      } else {
        setType(AfterRedeemedType.NONE);
      }
      setEvent({
        eventName: utilityCollection?.name || '',
        eventDescription: utilityCollection?.redeemedDescription || '',
      });
    } catch (e) {
      const error = e as AppError;
      Logger.error({ error });
      updateError(error);
    }
  };

  const joyIdHandler = async ({ contractAddress, tokenId }: IUtilityCollectionNFTs) => {
    try {
      const value = {
        type: 'evm',
        chainId: Number(chainId),
        utilityCollectionId,
        contractAddress,
        tokenId: Number(tokenId),
        address: joyIdAddress,
      };
      const redeemMessage: any = await getRedeemMessage(value);
      const signature = await joyIdSignTypedData(redeemMessage, joyIdAddress);
      const { utilityUri } = await redeem({
        type: 'evm',
        redeemMessage,
        signature,
      });

      if (utilityUri) {
        // TODO
        const { type, value } = await getUtilityData({
          utilityUri,
          address: joyIdAddress,
        });
        setQrCode(value);

        if (type === UtilityType.QR_CODE) {
          setType(AfterRedeemedType.QR_CODE);
        } else if (type === UtilityType.STRING) {
          setType(AfterRedeemedType.QR_STRING);
        }
      } else {
        setType(AfterRedeemedType.NONE);
      }
      setEvent({
        eventName: utilityCollection?.name || '',
        eventDescription: utilityCollection?.redeemedDescription || '',
      });
    } catch (e) {
      const error = e as AppError;
      Logger.error({ error });
      updateError(error);
    }
  };

  const okxHandler = async ({ contractAddress, tokenId }: IUtilityCollectionNFTs) => {
    try {
      const value = {
        type: 'evm',
        chainId: Number(chainId),
        utilityCollectionId,
        contractAddress,
        tokenId: Number(tokenId),
        address: okxAddress!,
      };
      const redeemMessage = await getRedeemMessage(value);
      const signature = await signTypedData(okxProvider, okxAddress!, redeemMessage);
      const { utilityUri } = await redeem({
        type: 'evm',
        redeemMessage,
        signature,
      });

      if (utilityUri) {
        // TODO
        const { type, value } = await getUtilityData({
          utilityUri,
          address: okxAddress,
        });
        setQrCode(value);

        if (type === UtilityType.QR_CODE) {
          setType(AfterRedeemedType.QR_CODE);
        } else if (type === UtilityType.STRING) {
          setType(AfterRedeemedType.QR_STRING);
        }
      } else {
        setType(AfterRedeemedType.NONE);
      }
      setEvent({
        eventName: utilityCollection?.name || '',
        eventDescription: utilityCollection?.redeemedDescription || '',
      });
    } catch (e) {
      const error = e as AppError;
      Logger.error({ error });
      updateError(error);
    }
  };

  const handleCloseModal = () => {
    setQrCode('');
    setEvent({ eventName: '', eventDescription: '' });
  };

  useEffect(() => {
    if (address && utilityCollectionId) {
      getUtilityCollection({ type: 'evm', chainId: Number(chainId), utilityCollectionId })
        .then((data) => setUtilityCollection((data as IUtilityCollectionById) || {}))
        .catch((e) => {
          const error = e as AppError;
          Logger.error({ error });
          updateError(error);
          setUtilityCollection(undefined);
        });
    }
  }, [address, utilityCollectionId, chainId]);

  useEffect(() => {
    if (address && utilityCollection) {
      getNFTsByUtilityCollectionId({
        type: 'evm',
        chainId: Number(chainId),
        utilityCollectionId,
      })
        .then(async (rawNfts) => {
          setIsLoading(true);
          let promises: Promise<IUtilityCollectionNFTs>[] = [];
          utilityCollection.contractAddresses.forEach((contractAddr) => {
            const contractNfts = rawNfts[contractAddr];
            promises = Object.keys(contractNfts).map(async (tokenId) => {
              const response = await fetch(contractNfts[tokenId]);
              const result = await response.json();
              return {
                ...result,
                tokenId,
                contractAddress: contractAddr,
              };
            });
          });

          const response = await Promise.allSettled(promises);
          const results = response
            .map((item) => {
              if (item.status === 'fulfilled') {
                return item.value;
              }
              return null;
            })
            .filter((item) => !!item) as IUtilityCollectionNFTs[];

          setNFTs(results);
        })
        .catch((e) => {
          const error = e as AppError;
          Logger.error({ error });
          updateError(error);
          setNFTs([]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [refreshKey, utilityCollection, address]);

  return (
    <>
      <Loading effect={loadingEffect} isLoading={loading} />
      <StyledContainer>
        <RedemptionInformation utilityCollection={utilityCollection} />
        {!isUtilityCollectionEnd ? (
          <StyledWrapper>
            <StyledSelectNftRow>
              <div>
                <StyledSelectNftTitle>Select an NFT to redeem</StyledSelectNftTitle>
                <StyledSelectNftSubTitle>Show only available NFTs</StyledSelectNftSubTitle>
              </div>
              <StyledDesktopRender>
                <RefreshButton width="140px" onRefresh={refreshNfts} />
              </StyledDesktopRender>
            </StyledSelectNftRow>
            {nfts.length === 0 ? (
              <EventIsEmpty utilityCollectionId={utilityCollectionId} onRefresh={refreshNfts} />
            ) : (
              <StyledNtfsRow>
                {nfts.map((nft) => (
                  <CollectionCard
                    key={nft.tokenId}
                    nft={nft}
                    handleRedeem={handleClick}
                    defaultImg={utilityCollectionDefaultImg}
                  />
                ))}
              </StyledNtfsRow>
            )}
            <StyledMobileRender>
              <StyledRefreshContainer>
                <RefreshButton onRefresh={refreshNfts} />
              </StyledRefreshContainer>
            </StyledMobileRender>
          </StyledWrapper>
        ) : (
          <StyledWrapper>
            <EventIsFull />
          </StyledWrapper>
        )}
        <QrCodeModal
          qrCode={qrCode}
          redemptionEvent={event}
          redemptionType={type}
          opened={!!event.eventDescription}
          onClose={handleCloseModal}
        />
      </StyledContainer>
    </>
  );
};

const CollectionsWithContext = () => (
  <Page>
    <CollectionsPage />
  </Page>
);

export default CollectionsWithContext;
