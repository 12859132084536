import { DiscordIcon, FacebookIcon, TwitterIcon, homeLogoImage } from '@styles/imgMapping';

interface ICustomConfig {
  [componentKey: string]: {
    [fieldKey: string]: {
      isShow?: boolean;
      link?: string;
      imgSrc?: string;
      imgHoverSrc?: string;
    };
  };
}

export default {
  footer: {
    logo: {
      link: 'https://www.sandbox.game/zh-TW/',
      imgSrc: homeLogoImage,
      width: 495,
      mobileWidth: 300,
    },
    twitter: {
      isShow: true,
      link: 'https://twitter.com/thesandboxgame',
      imgSrc: TwitterIcon,
    },
    discord: {
      isShow: true,
      link: 'https://discord.com/invite/thesandboxgame',
      imgSrc: DiscordIcon,
    },
    facebook: {
      isShow: true,
      link: 'https://www.facebook.com/redreamer.io',
      imgSrc: FacebookIcon,
    },
  },
} as ICustomConfig;
