import React from 'react';
import Collections from '@pages/CollectionsPage/CollectionsPage';
import ErrorPage from '@pages/ErrorPage/ErrorPage';
import Home from '@pages/Home';
import UtilityCollectionsPage from '@/pages/UtilityCollectionsPage';

export default [
  {
    id: 'home',
    name: 'Home',
    path: '/',
    component: (props: any) => <Home {...props} description="Home" />,
  },
  {
    id: 'collection',
    name: 'Collection',
    path: '/campaigns/:utilityCollectionId',
    auth: true,
    component: (props: any) => <Collections {...props} description="Collections" />,
  },
  {
    id: 'campaigns',
    name: 'Campaigns',
    path: '/campaigns',
    component: (props: any) => <UtilityCollectionsPage {...props} description="Campaigns Page" />,
  },
  {
    id: 'error',
    name: 'Error',
    path: '/error',
    component: (props: any) => <ErrorPage {...props} description="Error" />,
  },
];
